.footer {
    bottom: 0;
    /* position: fixed; */
    width: 100%;
    background-image: linear-gradient(to left, black, #353535);
    height: 40px;
    color: white;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    margin-top: 40px;
}

@media screen and (max-width: 990px) {
    .footer {
        bottom: 0;
        position: unset;
    }
}