.header {
    width: 100%;
    background-image: linear-gradient(to right, black, #353535);
    height: 60px;
    display: flex;
    justify-content: end;
    align-items: center;
    color: aliceblue;
}

.title {
    color: #ffffff;
    font-size: 30px;
    font-weight: bold;
    margin-left: 60px;
}