.panel-selection {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 10px;
}

.panel-selection > .title-options {
    color: white;
    font-size: 26px;
    font-weight: bold;
    text-transform: inherit;
    margin-bottom: 10px;
}

.panel-selection .options {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
    padding: 10px;
    max-width: 680px;
    height: 360px;
    overflow-y: scroll;
    border: 2px solid white;
    border-radius: 5px 0px 0px 5px;
}

.option-selection{
    margin: 0px 5px;
    display: flex;
    align-items: center;
    cursor: pointer;
    flex-direction: column;
    color: white;
    font-weight: bold;
}

.option-selection > img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
}

.option-selection:hover > img {
    border: 3px solid yellow;
}

.option-selection:hover > .alias {
    color: yellow;
    text-decoration: underline;
}

.option-selection.selected > img{
    border: 3px solid #d10000;
}

.option-selection.selected > .alias {
    color: #d10000;
    text-decoration: underline;
}   

.option-selection .alias {
    width: 90px;
    text-align: center;
}

@media screen and (max-width: 990px) {

    .panel-selection .options {
        max-width: 600px;
        height: 300px;
        overflow-y: auto;
        padding: 5px;
    }

}

@media screen and (max-width: 510px){

    .option-selection > img {
        width: 90px;
        height: 90px;
    }

    .panel-selection > .title-options {
       font-size: 20px;
    }

}