.background {
    background-color:black;
    height: 100vh;
}

.display-flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    margin: 20px 0px 20px 0px;
}

.side-select {
    align-self: flex-start;
}

.player-selected {
    color: white;
    font-size: 26px;
    font-weight: bold;
    margin: 10px 0px 5px 0px;
}

.actions-generator {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
    margin: 0px 20px 20px 20px;
}

.input-number {
    display: flex;
    flex-direction: column;
}

.input-number > .label {
    color: white;
    padding-bottom: 2px;
    font-weight: bold;
}

.input-number > input {
    border-radius: 5px;
    padding-left: 5px;
    font-weight: bold;
    background-color: black;
    color: white;
    height: 38px;
    width: inherit;
    text-align: end;
}

.input-number > span {
    padding: 0px 10px 0px 5px;
}

.input-number > input::placeholder {
    font-size: 14px;
    color: #00000082;
    padding-left: 5px;
}

.about {
    color: white;
    font-weight: bold;
    width: 75%;
    display: flex;
    flex-direction: column;
    margin: 5px auto 20px auto;
}

.contribution {
    color: white;
    width: 100%;
    background-color: #181818;
    padding-bottom: 45px;
    padding-top: 5px;
    display: flex;
    flex-direction: column;
    font-weight: bold;
}

.about > .about-title {
    font-size: 32px;
    text-align: center;
}

.about > .about-desc,
.contribution > .contribution-desc {
    text-align: center;
    padding: 10px;
}

.git-information {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    padding: 0 5px;
}

.git-icon {
    color: #b96dff;
    font-size: 74px;
    margin: 0 20px;
    border-right: 1px solid white;
    padding-right: 20px;
}

.git-link {
    display: grid;
    text-align: start;
}

.m-10 {
 margin: 10px;   
}

.mr-10 {
    margin-right: 10px;
}

.mb-10 {
    margin-bottom: 10px;
}

.mt-10 {
    margin-top: 10px;
}

.mb-5 {
    margin-bottom: 5px;
}

.mt-5 {
    margin-top: 5px;
}

.color-green {
    color: green;
}

.color-purple {
    color: purple;
}

.break-word {
    word-break: break-word;
}

@media screen and (max-width: 990px) {
    
    .display-flex-center {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        height: auto;
    }

}

@media screen and (max-width: 660px) {
    
    .about {
        width: 90%;
    }

}
